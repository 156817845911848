


































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiSelffetchVerificationList,
    apiSelffetchVerificationQuery,
    apiSelffetchVerification
} from '@/api/application/selffetch'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import DatePicker from '@/components/date-picker.vue'
import ExportData from '@/components/export-data/index.vue'

@Component({
    components: {
        LsDialog,
        DatePicker,
        LsPagination,
        ExportData
    }
})
export default class SelffetchVerification extends Vue {
    /** S Data **/
    // 表单数据
    form = {
        pickup_code: '', // 提货码
        verification_status: '', // 核销状态: 0-待核销; 1-已核销;
        order_sn: '', // 订单信息
        user_info: '', // 用户信息
        goods_name: '', // 商品名称
        contact_info: '', // 收货人信息
        time_type: '', // 时间类型: create_time-下单时间; pay_time-支付时间
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        order_type_admin: '', // 订单类型
        order_status: '' // 订单状态
    }
    pager: RequestPaging = new RequestPaging()
    tempOrderInfo = {} // 暂存订单信息：用于订单查询
    apiSelffetchVerificationList = apiSelffetchVerificationList
    /** E Data **/

    /** S Methods **/
    // 搜索
    search() {
        this.pager.page = 1
        if (
            (this.form.start_time || this.form.end_time) &&
            !this.form.time_type
        ) {
            return this.$message.error('选择时间必须选择时间类型!')
        }
        this.getSelffetchVerifierList()
    }

    // 重置搜索
    resetSearch() {
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getSelffetchVerifierList()
    }

    // 获取列表数据
    getSelffetchVerifierList() {
        // 请求管理员列表
        this.pager
            .request({
                callback: apiSelffetchVerificationList,
                params: this.form
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 订单核销
    onSelffetchOrderVerification(id: number) {
        apiSelffetchVerification({
            id
        }).then(() => {
            // 订单核销成功就请求新列表
            this.getSelffetchVerifierList()
        })
    }

    // 去订单详情
    goSelffetchOrderDetail(id: number) {
        this.$router.push({
            path: '/order/order_detail',
            query: { id: id + '' }
        })
    }

    // 订单查询
    onSelffetchOrderQuery(id: number) {
        apiSelffetchVerificationQuery({
            id
        }).then(data => {
            this.tempOrderInfo = data
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getSelffetchVerifierList()
    }

    /** E Life Cycle **/
}
